.Main-Text {
  display: inline-flex;
  /* border: 1px solid blue; */
  font-size: calc(9px + 2vmin);
}

.Post-Text {
  font-size: calc(9px + 2vmin);
}

.Post-Text-Invis {
  font-size: calc(9px + 2vmin);
  color: transparent;
}



